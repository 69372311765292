<template>
  <div>
    <input
      @change="toggleTheme"
      id="checkbox"
      type="checkbox"
      class="switch-checkbox"
    />
    <label for="checkbox" class="switch-label">
      <span>🌙</span>
      <span>☀️</span>
      <div
        class="switch-toggle"
        :class="{ 'switch-toggle-checked': userTheme === 'dark-theme' }"
      ></div>
    </label>
  </div>
</template>

<script lang="ts">
export default {
  mounted() {
    const initUserTheme = this.getTheme() || this.getMediaPreference();
    this.setTheme(initUserTheme);
  },

  data() {
    return {
      userTheme: "light-theme",
    };
  },

  methods: {
    toggleTheme() {
      const activeTheme = localStorage.getItem("user-theme");
      if (activeTheme === "light-theme") {
        this.setTheme("dark-theme");
      } else {
        this.setTheme("light-theme");
      }
    },

    getTheme() {
      return localStorage.getItem("user-theme");
    },

    setTheme(theme: string) {
      localStorage.setItem("user-theme", theme);
      this.userTheme = theme;
      document.documentElement.className = theme;
    },

    getMediaPreference() {
      const hasDarkPreference = window.matchMedia(
        "(prefers-color-scheme: dark)"
      ).matches;
      if (hasDarkPreference) {
        return "dark-theme";
      } else {
        return "light-theme";
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.switch-checkbox {
  display: none;
}

.dark-theme .switch-label {
  background: var(--stier-dark-grey);
}

.light-theme .switch-label {
  background: var(--stier-white);
}

.switch-label {
  align-items: center;
  border-radius: 4rem;
  cursor: pointer;
  display: flex;
  height: 32px;
  position: relative;
  transition: background 0.5s ease;
  justify-content: space-between;
  width: 56px;
  z-index: 1;
  padding: 0 6px;
}

.dark-theme .switch-toggle {
  background-color: var(--stier-medium-light-grey);
  border: var(--stier-light-grey);
}

.light-theme .switch-toggle {
  background-color: var(--stier-dark-grey);
  border: var(--stier-black);
}

.switch-toggle {
  position: absolute;
  border-width: 2px;
  border-style: solid;
  border-radius: 50%;
  top: auto;
  left: 6px;
  height: 18px;
  width: 18px;
  transform: translateX(0);
  transition: transform 0.3s ease, background-color 0.5s ease;
}

.switch-toggle-checked {
  transform: translateX(26px) !important;
}
</style>
