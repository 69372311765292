import { defineStore } from "pinia";
import { ref } from "vue";

export const useConnectivityStore = defineStore("connectivity", () => {
  const online = ref(true);

  window.addEventListener("online", () => {
    online.value = true;
  });
  window.addEventListener("offline", () => {
    online.value = false;
  });

  /**
   * Returns the current internet connectivity.
   */
  function getConnectivity() {
    return online.value;
  }

  return { getConnectivity };
});
