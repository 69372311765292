import getEnv from "@/utils/env";
import { createAuth0 } from "@auth0/auth0-vue";

const auth0 = createAuth0({
  cacheLocation: "localstorage",
  client_id: getEnv("VITE_AUTH0_CLIENT_ID"),
  domain: getEnv("VITE_AUTH0_DOMAIN"),
  redirect_uri: window.location.origin,
  useRefreshTokens: true,
});

export default auth0;
