import { z } from "zod";
import { v4 as uuidv4 } from "uuid";

const countSchema = z.number().nonnegative();
const linearFeetSchema = z.number().nonnegative();
const dateFromString = z.preprocess((arg) => {
  if (typeof arg === "string" || arg instanceof Date) return new Date(arg);
}, z.date());

const resourceBaseSchema = z
  .object({
    id: z.string().uuid().default(uuidv4),
    created_at: dateFromString.default(() => new Date().toISOString()),
    updated_at: dateFromString.default(() => new Date().toISOString()),
    deleted_at: z.date().nullable().default(null),
    saved_in_pinia_store: z
      .boolean()
      .default(false)
      .describe(
        "Whether the resource exists in a Pinia store on the frontend, this " +
          "is used to determine whether to create local copy of the resource " +
          "before modifying it. This value exists only on the frontend."
      ),
    originated_from_remote_database: z
      .boolean()
      .default(false)
      .describe(
        "Whether the resource came from the remote database, this is used " +
          "when saving to the remote database to determine whether to request" +
          " that a new resource is made, or that the modifications be applied" +
          " to an existing resource. This value exists only on the frontend."
      ),
  })
  .describe(
    "An object largely matching the backend's ResourceBase, it contains the " +
      "basic metadata tracked on all resources stored in the remote database." +
      " It also includes a couple of extra fields used for bookkeeping on the " +
      "frontend."
  );
type ResourceBase = z.infer<typeof resourceBaseSchema>;
const squareFeetSchema = linearFeetSchema;
const yesNoOrNothingSchema = z.enum(["Yes", "No", "N/A", ""]).default("");
type YesNoOrNothing = z.infer<typeof yesNoOrNothingSchema>;

export {
  countSchema,
  linearFeetSchema,
  resourceBaseSchema,
  squareFeetSchema,
  yesNoOrNothingSchema,
};
export type { ResourceBase, YesNoOrNothing };
