import { createRouter, createWebHistory } from "vue-router";
import { useTrimCompletionFormStore, useImageQueueStore } from "@/stores";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      name: "home",
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import("@/views/HomeView.vue"),
    },
    {
      path: "/trim-completion-form",
      name: "trim completion form",
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import("@/views/TrimCompletionFormView.vue"),
    },
    {
      path: "/view-trim-completion-form/:id",
      name: "view trim completion form",
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import("@/views/ViewTrimCompletionFormView.vue"),
    },
    {
      path: "/trim-completion-form/:id",
      name: "edit trim completion form",
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import("@/views/TrimCompletionFormView.vue"),
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  const trimCompletionFormStore = useTrimCompletionFormStore();
  await trimCompletionFormStore.$persistedState.isReady();
  const imageQueueStore = useImageQueueStore();
  await imageQueueStore.$persistedState.isReady();
  next();
});

export default router;
