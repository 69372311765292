import { z } from "zod";

const jobSearchResultSchema = z.object({
  address_plus: z.string().default(""),
  city: z.string().default(""),
  city_id: z.number().default(-1),
  community: z.string().default(""),
  community_id: z.number().default(-1),
  customer: z.string().default(""),
  customer_active: z.boolean().default(false),
  customer_id: z.number().default(-1),
  floor_plan: z.string().default(""),
  floor_plan_id: z.number().default(-1),
  id: z.number().default(-1),
  job_active: z.boolean().default(false),
  label: z.string().default(""),
  supervisor: z.string().default(""),
  supervisor_full_name: z.string().default(""),
  supervisor_id: z.number().default(-1),
  value: z.string().default(""),
});
type JobSearchResult = z.infer<typeof jobSearchResultSchema>;

export { jobSearchResultSchema };
export type { JobSearchResult };
