import api, {
  trimCompletionFormSchema,
  imageSchema,
  type TrimCompletionForm,
  type Image,
} from "@/api";
import { useConnectivityStore } from "@/stores/connectivity";
import LogRocket from "logrocket";
import { defineStore } from "pinia";
import { reactive } from "vue";
import PQueue from "p-queue";

/**
 * We manage trim completion forms' state in a store to create a central
 * location to manage the lifecycle of trim completion forms and to enabled
 * working with these forms while offline by persisting the store to IndexedDB
 * and rehydrating it before we route to a page.
 *
 * The basic strategy you'll see here is to prioritize interacting with our
 * local copy in IndexedDB over any remote copies, while also deleting our local
 * copies as soon as we know they are saved to the remote database. Since we
 * want to be interacting with the latest version of a trim completion form,
 * this may seem counter-intuitive, but we operate under the assumption that we
 * are the only ones modifying this trim completion form, and thus our copy must
 * be the latest. By pairing this with the idea of removing local forms from
 * IndexedDB as soon as we know they are saved to the remote database, further
 * actions will naturally interact with the form with the most recent changes -
 * a local form first, if we still have one, otherwise the remote form, if the
 * local form was deleted after successfully saving to the remote database.
 *
 * Another benefit of this strategy is that, when sending out a new trim
 * completion form to be created, where the backend ignores our temporary uuid
 * and generates its own, we don't need to update our local copy with the new
 * uuid. We simply delete our local copy, leaving future actions to naturally
 * interact with the new remote form.
 *
 * Managing the lifecycle of trim completion forms in this way, operating off a
 * local copy until we save it back into the remote database, each form is like
 * its own git repository. While making changes to the local copy of the form,
 * your "branch" is likely to have the latest changes, but the longer you wait,
 * the more likely it is for your changes to the form to "diverge from the main
 * branch". By deleting the local form upon saving it to the remote database, we
 * reduce the time we are operating on a "branch" that may diverge, thereby
 * "Merging Early and Merging Often".
 *
 * Extending the git analogy, it is important to note that every time we save to
 * the remote database, we are effectively `git --force push`ing, that is,
 * overwriting any changes on remote with our own. We consider this to be
 * acceptable, at least as a first pass, as we only expect one person to be
 * working on a form at any given time.
 */

export const useImageQueueStore = defineStore("imageQueue", () => {
  const images: Record<string, Array<Image>> = reactive({});
  const queue = new PQueue({ autoStart: true, concurrency: 1 });

  queue.on("active", () => {
    console.log("Upload queue active");
  });

  queue.on("completed", (result) => {
    console.log("Upload complete:  ", result);
  });

  queue.on("error", (error) => {
    console.log("Upload error:  ", error);
  });

  queue.on("idle", () => {
    console.log("Upload queue idle");
  });

  async function shelveImages(
    owner_id: string,
    type: string,
    uploads: Array<Image>
  ) {
    if (!images[owner_id] && uploads.length > 0) {
      images[owner_id] = [];
    }
    uploads
      .flat()
      .filter((u) => u.local)
      .forEach((u) => {
        images[owner_id].push(
          imageSchema.parse({
            ...u,
            owner_id: u.owner_id || owner_id,
            owner_type: type,
          })
        );
      });
    return images[owner_id];
  }

  async function uploadImages(id: string, type: string) {
    if (images[id] && images[id].length > 0) {
      for (let i = 0; i < images[id].length; i++) {
        const img = images[id][i];
        if (img && img.owner_type === type && img.local) {
          try {
            await queue.add(async function ({ signal }) {
              console.log("Queueing upload", signal);
              const formData = new FormData();
              if (img.resource_base && img.resource_base.id) {
                formData.append("id", img.resource_base.id);
              }
              formData.append("form_field", img.form_field);
              formData.append("file", img.local);
              return await api.trimCompletionImageUpload(formData, {
                params: { owner_type: type, id: String(img.owner_id) },
              });
            });
            images[id].splice(i, 1);
            i--;
          } catch (error) {
            console.log("upload failed -- we'll try again later", error);
          }
        }
      }
      if (images[id].length === 0) {
        delete images[id];
      }
    }
  }

  return {
    shelveImages,
    uploadImages,
    images,
  };
});

export const useTrimCompletionFormStore = defineStore(
  "trimCompletionForm",
  () => {
    const templates: Record<string, TrimCompletionForm> = reactive({});
    const forms: Record<string, TrimCompletionForm> = reactive({});
    const connectivityStore = useConnectivityStore();
    const imageQueueStore = useImageQueueStore();

    /**
     * Gets the first matching trim completion form.
     *
     * This prioritizes fetching trim completion forms saved locally, only
     * attempting to fetch the requested form from the remote database if no
     * matching form is found locally and an internet connection is available.
     *
     * @param param.jobID - the primary id for a trim completion form.
     * @returns a matching TrimCompletion form or `undefined` if not found.
     */
    async function getTrimCompletionForm({ id }: { id: number | string }) {
      const localForm = forms[String(id)];
      if (localForm) return localForm;

      if (connectivityStore.getConnectivity()) {
        return api
          .trimCompletionFormRead({ params: { id: String(id) } })
          .catch((e) => {
            if (e instanceof Error)
              LogRocket.captureException(e, {
                tags: {
                  trimCompletionFormID: id,
                },
              });
            return undefined;
          });
      }
    }

    /**
     * Gets all available trim completion forms.
     *
     * Collisions between the local and remote forms are resolved by
     * prioritizing showing the local copy, since that is more likely to have
     * the latest changes.
     *
     * @returns an array of all available trim completion forms.
     */
    async function getTrimCompletionForms() {
      const trimCompletionFormsToReturn: TrimCompletionForm[] =
        Object.values(forms);

      if (connectivityStore.getConnectivity()) {
        trimCompletionFormsToReturn.push(
          ...(await api.trimCompletionFormsRead().catch((e) => {
            if (e instanceof Error) LogRocket.captureException(e);
            return [];
          }))
        );
      }

      return (
        trimCompletionFormsToReturn
          // we want to sort descending by ID, with local copies coming after
          // remote copies, as ...
          .sort((formA, formB) =>
            formA.resource_base.id > formB.resource_base.id
              ? -1
              : formA.resource_base.id !== formB.resource_base.id
              ? 1
              : formA.resource_base.originated_from_remote_database &&
                formA.resource_base.saved_in_pinia_store
              ? 1
              : -1
          )
          // ... we want to replace remote copies with any matching local
          // copies, since the local copies are more likely to have the latest
          // changes
          .reduce((previousForms, currentForm) => {
            if (
              currentForm.resource_base.id ===
              previousForms.at(-1)?.resource_base.id
            )
              previousForms[previousForms.length - 1] = currentForm;
            else previousForms.push(currentForm);
            return previousForms;
          }, [] as TrimCompletionForm[])
          // we want to sort ascending by the date the resource was created, as
          // this will place the most recent forms at the top of the trim
          // completion table
          .sort(
            (formA, formB) =>
              formB.resource_base.created_at.valueOf() -
              formA.resource_base.created_at.valueOf()
          )
      );
    }

    /**
     * Gets a TrimCompletionForm form template, prefilled with any details that
     * could be found.
     *
     * This prioritizes fetching trim completion form templates saved locally,
     * only attempting to fetch the requested template from the remote database
     * if no matching template is found locally and an internet connection is
     * available.
     *
     * @param params.trimBoltJobID - the bolt job id attached to a trim
     * completion form.
     * @param params.trimBoltJobReference - the job reference attached to a trim
     * completion form.
     * @returns a trim completion form, prefilled with any available details
     */
    async function getTrimCompletionFormTemplate({
      trimBoltJobID,
      trimBoltJobReference,
    }: {
      trimBoltJobID?: number | string | null;
      trimBoltJobReference?: string | null;
    }) {
      const localForm = Object.values(templates).find(
        // we use == instead of === while searching since we want to take
        // advantage of considering both 1 == 1 and 2 == "2" to be true
        (form) =>
          form.job_information.bolt_job_id == trimBoltJobID ||
          form.job_information.job_reference === trimBoltJobReference
      );

      if (localForm) return localForm;

      if (
        !connectivityStore.getConnectivity() ||
        (!trimBoltJobID && !trimBoltJobReference)
      )
        return trimCompletionFormSchema.parse({});

      return api
        .trimCompletionFormTemplateRead({
          queries: {
            ...(trimBoltJobID && { trimBoltJobID: trimBoltJobID.toString() }),
            ...(trimBoltJobReference && {
              trimBoltJobReference: trimBoltJobReference,
            }),
          },
        })
        .catch((e) => {
          if (e instanceof Error)
            LogRocket.captureException(e, {
              tags: {
                ...(trimBoltJobID && { jobID: trimBoltJobID }),
                ...(trimBoltJobReference && {
                  jobReference: trimBoltJobReference,
                }),
              },
            });
          return trimCompletionFormSchema.parse({});
        });
    }

    /**
     * Saves the provided trim completion form, creating a new one if it didn't
     * previously exist or updating it if it did.
     *
     * If the form is saved to the remote database, the local copy is delete.
     *
     * @param params.trimCompletionForm - trim completion form to be saved
     * @param params.onlySaveLocally - only saves the form locally
     * @returns if the form was saved to the remote database, else it was saved
     * locally
     */
    async function saveTrimCompletionForm({
      trimCompletionForm,
      onlySaveLocally,
    }: {
      trimCompletionForm: TrimCompletionForm;
      onlySaveLocally?: boolean;
    }) {
      trimCompletionForm.resource_base.saved_in_pinia_store = true;
      forms[trimCompletionForm.resource_base.id] = trimCompletionForm;

      if (onlySaveLocally || !connectivityStore.getConnectivity()) return false;

      try {
        let newTC;
        if (trimCompletionForm.resource_base.originated_from_remote_database) {
          newTC = await api.trimCompletionFormUpdate(trimCompletionForm, {
            params: { id: trimCompletionForm.resource_base.id },
          });
        } else {
          newTC = await api.trimCompletionFormCreate(trimCompletionForm);
        }

        const tcFormID = newTC?.resource_base?.id;
        if (tcFormID) {
          await imageQueueStore.shelveImages(
            tcFormID,
            "trim_completion_forms",
            trimCompletionForm.images
          );
          await imageQueueStore.uploadImages(tcFormID, "trim_completion_forms");

          for (let i = 0; i < newTC.incomplete_work_tasks.length; i++) {
            const newTask = newTC.incomplete_work_tasks[i];
            const foundLocalTask =
              trimCompletionForm.incomplete_work_tasks.find(
                (iwt) =>
                  newTask.resource_base.id === iwt.resource_base.id ||
                  newTask.description === iwt.description
              );
            await imageQueueStore.shelveImages(
              tcFormID,
              "incomplete_work_tasks",
              foundLocalTask?.images?.map((img) =>
                imageSchema.parse({
                  ...img,
                  owner_id: newTask.resource_base.id,
                })
              )
            );
          }
          await imageQueueStore.uploadImages(tcFormID, "incomplete_work_tasks");

          // restore any remaining images to draft status
          if (
            imageQueueStore.images &&
            imageQueueStore.images[tcFormID] &&
            imageQueueStore.images[tcFormID].length > 0
          ) {
            trimCompletionForm = newTC;
            for (let i = 0; i < imageQueueStore.images[tcFormID].length; i++) {
              for (
                let j = 0;
                j < trimCompletionForm.incomplete_work_tasks.length;
                j++
              ) {
                if (
                  imageQueueStore.images[tcFormID][i].owner_id ===
                  trimCompletionForm.incomplete_work_tasks[j].resource_base.id
                ) {
                  const imgIndex = trimCompletionForm.incomplete_work_tasks[
                    j
                  ].images.findIndex(
                    (img) =>
                      img.filename ===
                      imageQueueStore.images[tcFormID][i].filename
                  );
                  if (imgIndex > -1) {
                    trimCompletionForm.incomplete_work_tasks[j].images[
                      imgIndex
                    ] = imageQueueStore.images[tcFormID][i];
                    break;
                  }
                }
              }
              imageQueueStore.images[tcFormID].splice(i, 1);
              i--;
            }
            trimCompletionForm.resource_base.originated_from_remote_database =
              true;
            trimCompletionForm.resource_base.saved_in_pinia_store = true;
          } else {
            deleteTrimCompletionForm(trimCompletionForm.resource_base.id);
          }
        }
      } catch (e) {
        console.log(e);
        if (e instanceof Error) LogRocket.captureException(e);
        return false;
      }

      return true;
    }

    /**
     * Saves the provided trim completion form locally as a template to be used
     * to create future trim completion forms.
     *
     * @param trimCompletionForm - trim completion form to be saved as a
     * template
     */
    function saveTrimCompletionFormTemplate(
      trimCompletionForm: TrimCompletionForm
    ) {
      templates[trimCompletionForm.resource_base.id] = trimCompletionForm;
    }

    /**
     * Deletes a trim completion form from the local store. Note that this does
     * *not* delete the trim completion form from the remote database.
     *
     * @param id - id of a trim completion form to be deleted
     * @returns whether the requested trim completion form was deleted.
     */
    function deleteTrimCompletionForm(id: string) {
      return delete forms[id];
    }

    /**
     * Deletes a trim completion form template from the local store.
     *
     * @param id - id of a trim completion form template to be deleted
     * @returns whether the requested trim completion form template was deleted.
     */
    function deleteTrimCompletionFormTemplate(id: string) {
      return delete templates[id];
    }

    return {
      deleteTrimCompletionForm,
      deleteTrimCompletionFormTemplate,
      getTrimCompletionForm,
      getTrimCompletionForms,
      getTrimCompletionFormTemplate,
      saveTrimCompletionForm,
      saveTrimCompletionFormTemplate,
      forms,
      templates,
    };
  }
);
