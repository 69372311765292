import App from "@/App.vue";
import { auth0, balmUI, pinia, router } from "@/plugins";
import "@/styles/base.css";
import "@/styles/index.scss";
import getEnv from "@/utils/env";
import LogRocket from "logrocket";
import { createApp } from "vue";

const logRocketAppID = getEnv("VITE_LOG_ROCKET_APP_ID");
if (logRocketAppID) {
  LogRocket.init(logRocketAppID);
}

createApp(App).use(auth0).use(pinia).use(router).use(balmUI).mount("#app");
