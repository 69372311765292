import {
  countSchema,
  linearFeetSchema,
  resourceBaseSchema,
  squareFeetSchema,
  yesNoOrNothingSchema,
} from "@/api/resource-base";
import { z } from "zod";

const documentCategorySchema = z.enum([
  "Plan",
  "Documents",
  "PO's",
  "Completions",
  "Photos",
]);

type DocumentCategory = z.infer<typeof documentCategorySchema>;
const documentSchema = z.object({
  category: documentCategorySchema.default("Photos"),
  filename: z.string().default(""),
  id: z.number().int().default(-1),
  file: z.string().default(""),
  thumbnail_url: z.string().default(""),
  url: z.string().default(""),
});
type Document = z.infer<typeof documentSchema>;

const imageSchema = z.object({
  resource_base: resourceBaseSchema.default({}).optional(),
  owner_id: z.string().uuid().optional(),
  owner_type: z.string().optional(),
  blob_container: z.string().optional(),
  blob_endpoint: z.string().optional(),
  form_field: z.string().optional(),
  file_type: z.string().optional(),
  filename: z.string().optional(),
  label: z.string().optional(),
  uploaded_by: z.string().optional(),
  local: z.instanceof(File).optional(),
});
type Image = z.infer<typeof imageSchema>;

const taskSchema = z.object({
  resource_base: resourceBaseSchema.default({}),
  form_id: z.string().uuid().optional(),
  description: z.string().default(""),
  images: z.array(imageSchema.default({})).nullable().optional().default([]),
});
type Task = z.infer<typeof taskSchema>;

const fmTrimReadyWalkSchema = z.object({
  is_cabinets_installed: yesNoOrNothingSchema,
  is_exteriors_installed: yesNoOrNothingSchema,
  is_house_cleaned_sanded: yesNoOrNothingSchema,
  is_materials_correct: yesNoOrNothingSchema,
  is_openings_correct: yesNoOrNothingSchema,
  is_windows_installed: yesNoOrNothingSchema,
  missing_materials: z.string().default(""),
});
type FMTrimReadyWalk = z.infer<typeof fmTrimReadyWalkSchema>;

const jobInformationSchema = z.object({
  address_line_1: z.string().default(""),
  address_line_2: z.string().default(""),
  area: z.string().default(""),
  bolt_job_id: z.number().int().default(-1),
  builder_name: z.string().default(""),
  city: z.string().default(""),
  trim_crew: z.string().default(""),
  date: z.string().default(""),
  job_reference: z.string().default(""),
  options_box: z.string().default(""),
  plan: z.string().default(""),
  spec: z.string().default(""),
  state: z.enum(["", "FL", "GA", "NC", "SC", "TN"]).default(""),
  status: z.enum(["Draft", "Submitted", "Approved"]).default("Draft"),
});
type JobInformation = z.infer<typeof jobInformationSchema>;

const signaturesSchema = z.object({
  area_manager: z.string().default(""),
  field_manager: z.string().default(""),
});

const stierStandardInspectionSchema = z.object({
  is_attic_access_installed_correctly: yesNoOrNothingSchema,
  is_doors_defect_free: yesNoOrNothingSchema,
  is_doors_installed_correctly: yesNoOrNothingSchema,
  is_exterior_doors_installed_correctly: yesNoOrNothingSchema,
  is_home_swept_and_trash_discarded: yesNoOrNothingSchema,
  is_materials_stacked_neatly: yesNoOrNothingSchema,
  is_stool_installed_correctly: yesNoOrNothingSchema,
  is_treads_installed_correctly: yesNoOrNothingSchema,
  is_trim_installed_correctly: yesNoOrNothingSchema,
  is_wall_rails_installed_correctly: yesNoOrNothingSchema,
});
type StierStandardInspection = z.infer<typeof stierStandardInspectionSchema>;

const trimCrewSignOffSchema = z.object({
  is_materials_available: yesNoOrNothingSchema,
});
type TrimCrewSignOff = z.infer<typeof trimCrewSignOffSchema>;

const qcWalkSchema = z.object({
  attic_stairs_count: countSchema.optional(),
  attic_scuttle_count: countSchema.optional(),
  barn_doors_count: countSchema.optional(),
  barn_doors_type: z.enum(["", "None", "Built", "Install Only"]).default(""),
  beam_linear_feet: linearFeetSchema.optional(),
  beam_type: z.enum(["", "None", "Built", "Install Only"]).default(""),
  bench_seat_linear_feet: linearFeetSchema.optional(),
  built_ins_linear_feet: linearFeetSchema.optional(),
  cased_openings_count: countSchema.optional(),
  chair_rail_linear_feet: linearFeetSchema.optional(),
  chair_rail_type: z.enum(["", "None", "1PC", "2PC"]).default(""),
  chairs_and_squares_linear_feet: linearFeetSchema.optional(),
  chairs_and_squares_type: z.enum(["", "None", "1PC", "2PC"]).default(""),
  coffer_linear_feet: linearFeetSchema.optional(),
  coffer_type: z.enum(["", "None", "Framed", "Not Framed"]).default(""),
  columns_count: countSchema.optional(),
  columns_type: z.enum(["", "None", "Interior", "Exterior"]).default(""),
  craftsman_casing_type: z
    .enum([
      "",
      "None",
      "Craftsman 5/4x6 Header",
      "Craftsman 3pc Header",
      "Craftsman 4pc Header",
    ])
    .default(""),
  craftsman_wainscoting_linear_feet: linearFeetSchema.optional(),
  crown_1_pc_linear_feet: linearFeetSchema.optional(),
  crown_2_pc_linear_feet: linearFeetSchema.optional(),
  drop_zone_linear_feet: linearFeetSchema.optional(),
  finished_basement_square_feet: squareFeetSchema.optional(),
  is_attic_access_wall_rail: yesNoOrNothingSchema,
  is_extra_effort: yesNoOrNothingSchema,
  is_extra_floor: yesNoOrNothingSchema,
  is_garage_trimmed: yesNoOrNothingSchema,
  is_more_than_5_doors_8_foot_0_inch: yesNoOrNothingSchema,
  is_more_than_5_doors_solid_core: yesNoOrNothingSchema,
  is_raised_house: yesNoOrNothingSchema,
  is_skirt_board: yesNoOrNothingSchema,
  is_sunroom: yesNoOrNothingSchema,
  is_two_story: yesNoOrNothingSchema,
  manager_comments: z.string().default(""),
  manual_adder_usd: countSchema.optional(),
  open_rail_linear_feet: linearFeetSchema.optional(),
  pocket_doors_count: countSchema.optional(),
  shelving_linear_feet: linearFeetSchema.optional(),
  shiplap_square_feet: squareFeetSchema.optional(),
  shiplap_type: z.enum(["", "None", "Ceiling", "Wall"]).default(""),
  treads_installed_type: z.enum(["", "None", "MDF", "Oak"]).default(""),
  window_jamb_count: countSchema.optional(),
  window_picture_frame_count: countSchema.optional(),
  window_stool_and_apron_count: countSchema.optional(),
  window_stool_and_apron_type: z
    .enum(["", "None", "Window Stool & Apron", "Window Stool & Apron 3 Sides"])
    .default(""),
});
type QCWalk = z.infer<typeof qcWalkSchema>;

const trimCompletionFormSchema = z.object({
  fm_trim_ready_walk: fmTrimReadyWalkSchema.default({}),
  punch_list_id: z.number().int().optional(),
  incomplete_work_tasks: z.array(taskSchema).default([]),
  job_information: jobInformationSchema.default({}),
  qc_walk: qcWalkSchema.default({}),
  resource_base: resourceBaseSchema.default({}),
  signatures: signaturesSchema.default({}),
  stier_standard_inspection: stierStandardInspectionSchema.default({}),
  trim_crew_sign_off: trimCrewSignOffSchema.default({}),
  images: z.array(imageSchema.default({})).default([]),
});
type TrimCompletionForm = z.infer<typeof trimCompletionFormSchema>;

export {
  documentSchema,
  stierStandardInspectionSchema,
  taskSchema,
  trimCompletionFormSchema,
  imageSchema,
};

export type {
  Document,
  DocumentCategory,
  FMTrimReadyWalk,
  JobInformation,
  QCWalk,
  StierStandardInspection,
  Task,
  TrimCompletionForm,
  TrimCrewSignOff,
  Image,
};
