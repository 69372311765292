import type { ZodiosPlugin } from "@zodios/core";
import type { AxiosError } from "axios";
import { useToast } from "balm-ui";

const $toast = useToast();

/**
 * This Zodios plugin intercepts a response, checks for status 200, and pops a
 * toast. This plugin is intended to be applied to individual endpoints only.
 */
export function ZodiosSuccessToastPlugin(): ZodiosPlugin {
  return {
    name: "SuccessToasts",
    response: async (_, __, response) => {
      if (response.status === 200) {
        $toast({
          className: "stier-success-toast",
          message: `Request successfully completed`,
        });
      }
      return response;
    },
  };
}

const isAxiosError = (error: Error): error is AxiosError => {
  return "response" in error;
};

/**
 * This Zodios plugin intercepts a request error and pops a toast reporting the
 * issue. This plugin is intended to be applied globally.
 */
export function ZodiosErrorToastPlugin(): ZodiosPlugin {
  return {
    name: "ErrorToasts",
    error: async (_, __, error) => {
      if (isAxiosError(error))
        $toast({
          className: "stier-error-toast",
          message: `${error.name ?? "Error"}:  ${
            error.response?.data?.message ?? error.message
          }`,
        });
      throw error;
    },
  };
}
