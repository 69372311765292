import { jobSearchResultSchema } from "@/api/bolt";
import {
  documentSchema,
  trimCompletionFormSchema,
} from "@/api/trim-completion-form";
import auth0 from "@/plugins/auth0";
import getEnv from "@/utils/env";
import type { GetTokenSilentlyOptions } from "@auth0/auth0-spa-js";
import { Zodios } from "@zodios/core";
import { pluginToken } from "@zodios/plugins";
import { z } from "zod";
import {
  ZodiosSuccessToastPlugin,
  ZodiosErrorToastPlugin,
} from "@/plugins/zodios";

const api = new Zodios(`//${getEnv("VITE_API_URL")}`, [
  {
    alias: "boltDocumentRead",
    description: "Gets a Bolt document",
    method: "get",
    path: "/bolt/job/:jobId/documents/:documentId",
    response: documentSchema,
  },
  {
    alias: "boltJobSearch",
    description: "Searches Bolt for up to 30 jobs matching jobs",
    method: "get",
    path: "/bolt/jobs/:query",
    response: z.array(jobSearchResultSchema),
  },
  {
    alias: "trimCompletionFormCreate",
    description: "Creates a trim completion form",
    method: "post",
    path: "/trimCompletionForm",
    parameters: [
      {
        name: "trimCompletionForm",
        type: "Body",
        schema: trimCompletionFormSchema,
      },
    ],
    response: trimCompletionFormSchema,
  },
  {
    alias: "trimCompletionImageUpload",
    description: "Creates a trim completion form",
    method: "post",
    path: "/:owner_type/:id/upload",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: z.instanceof(FormData),
      },
    ],
    response: z.string(),
  },
  {
    alias: "trimCompletionFormRead",
    description: "Gets a trim completion form",
    method: "get",
    path: "/trimCompletionForm/:id",
    response: trimCompletionFormSchema.transform((form) => {
      form.resource_base.originated_from_remote_database = true;
      return form;
    }),
  },
  {
    alias: "trimCompletionFormTemplateRead",
    description: "Gets a trim completion form template",
    method: "get",
    parameters: [
      {
        name: "trimBoltJobReference",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "trimBoltJobID",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    path: "/trimCompletionForm",
    response: trimCompletionFormSchema,
  },
  {
    alias: "trimCompletionFormsRead",
    description: "Gets all trim completion forms",
    method: "get",
    path: "/trimCompletionForms",
    response: z.array(
      trimCompletionFormSchema.transform((form) => {
        form.resource_base.originated_from_remote_database = true;
        return form;
      })
    ),
  },
  {
    alias: "trimCompletionFormUpdate",
    description: "Updates a trim completion form",
    method: "put",
    path: "/trimCompletionForm/:id",
    parameters: [
      {
        name: "trimCompletionForm",
        type: "Body",
        schema: trimCompletionFormSchema,
      },
    ],
    response: trimCompletionFormSchema,
  },
]);

/**
 * Creates a Zodios plugin that fetches a token from auth0
 * @param getTokenSilentlyOptions - options for fetching an access token
 * @returns a promise of an access token
 */
const token = (getTokenSilentlyOptions?: GetTokenSilentlyOptions) =>
  pluginToken({
    getToken: async () => auth0.getAccessTokenSilently(getTokenSilentlyOptions),
  });

// this plugin will pop an error toast for failed Zodios requests
api.use(ZodiosErrorToastPlugin());

/**
 * GET Requests and middleware plugins defined below.
 */

// token plugin populates scoped token
api.use("boltDocumentRead", token({ scope: "read:bolt_document" }));

// token plugin populates scoped token
api.use("boltJobSearch", token({ scope: "read:bolt_job" }));

// token plugin populates scoped token
api.use("trimCompletionFormRead", token({ scope: "read:trim_completion" }));

// token plugin populates scoped token
api.use(
  "trimCompletionFormTemplateRead",
  token({ scope: "read:trim_completion read:bolt_job" })
);

// token plugin populates scoped token
api.use("trimCompletionFormsRead", token({ scope: "read:trim_completion" }));

/**
 * POST/PUT Requests and middleware plugins defined below.
 */
api.use(
  "trimCompletionImageUpload",
  token({ scope: "create:trim_completion" })
);

// token plugin populates scoped token
// success toast plugin pops toast if request succeeds
api.use("trimCompletionFormCreate", token({ scope: "create:trim_completion" }));
api.use("trimCompletionFormCreate", ZodiosSuccessToastPlugin());

// token plugin populates scoped token
// success toast plugin pops toast if request succeeds
api.use(
  "trimCompletionFormUpdate",
  token({ scope: "read:trim_completion update:trim_completion" })
);
api.use("trimCompletionFormUpdate", ZodiosSuccessToastPlugin());

export default api;
