<script setup lang="ts">
import { useToast } from "balm-ui";
import { useRegisterSW } from "virtual:pwa-register/vue";
import { watch } from "vue";

const $toast = useToast();
const { offlineReady, needRefresh, updateServiceWorker } = useRegisterSW();

function close() {
  needRefresh.value = false;
}

watch(offlineReady, () => {
  if (offlineReady.value) {
    $toast({
      className: "stier-success-toast",
      message: `App ready to use offline`,
    });
  }
});
</script>

<template>
  <div v-if="needRefresh" class="pwa-toast" role="alert">
    <p>Update available. Click reload to update.</p>
    <ui-button @click="updateServiceWorker" outlined>Reload</ui-button>
    <ui-button @click="close" outlined>Close</ui-button>
  </div>
</template>

<style lang="scss" scoped>
.pwa-toast {
  position: fixed;
  right: 0;
  bottom: 0;
  margin: 16px;
  padding: 12px;
  background-color: var(--mdc-theme-surface-secondary);
  border-radius: 12px;
  z-index: 1;
  text-align: left;

  & button {
    margin-top: 8px;
    height: 32px;

    &:not(:last-child) {
      margin-right: 8px;
    }
  }
}
</style>
