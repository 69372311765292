import BalmUI, { helpers } from "balm-ui";
import BalmUIPlus from "balm-ui-plus";
import type { Plugin } from "vue";

const balmUI: Plugin = {
  install: (app) => {
    app
      .use(BalmUI, {
        $validator: {
          required: {
            validate(value: unknown) {
              return !helpers.isEmpty(value);
            },
            message: "%s is required",
          },
          nonnegative: {
            validate(value: number) {
              return value >= 0;
            },
            message: "%s cannot be negative",
          },
        },
      })
      .use(BalmUIPlus);
  },
};

export default balmUI;
