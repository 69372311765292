<script setup lang="ts">
import ThemeButton from "@/components/ThemeButton.vue";
import { useConnectivityStore } from "@/stores";
import { useAuth0 } from "@auth0/auth0-vue";
import { ref } from "vue";
import { useRouter } from "vue-router";

const connectivityStore = useConnectivityStore();
const showAccountDropDown = ref(false);
const { user, logout } = useAuth0();

const router = useRouter();
const goHome = () => {
  router.push("/");
};

const accountDropDownItems = [
  {
    text: "Log Out",
    action: () => {
      logout({ returnTo: window.location.origin });
    },
  },
];

function onAccountDropDownItemSelected(data: { index: number; text: string }) {
  accountDropDownItems[data.index].action();
}
</script>

<template>
  <div class="header">
    <div class="header-container">
      <div class="header-logo-text" @click="goHome">
        <div class="header-logo"></div>
        Stier Supply Company
      </div>
      <theme-button class="theme-btn" />
      <div class="username">
        {{ user.name }}
      </div>
      <ui-menu-anchor>
        <ui-button @click="showAccountDropDown = true">
          <template #after>
            <ui-badge
              overlap
              dot
              :state="connectivityStore.getConnectivity() ? 'success' : 'error'"
            >
              <ui-icon>account_circle</ui-icon>
            </ui-badge>
            <ui-icon>arrow_drop_down</ui-icon>
          </template>
        </ui-button>
        <ui-menu
          :items="accountDropDownItems"
          @selected="onAccountDropDownItemSelected"
          position="BOTTOM_START"
          v-model="showAccountDropDown"
        >
        </ui-menu>
      </ui-menu-anchor>
    </div>
  </div>
</template>

<style scoped lang="scss">
@use "@material/button" as button;
.mdc-button {
  @include button.ink-color(var(--mdc-theme-on-primary));
}

.header-logo-text {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.03em;
  cursor: pointer;
}

.header {
  .dark-theme & {
    background: var(--stier-background-black);
  }
  .light-theme & {
    background: var(--stier-blue-gradient);
  }
}

.header-container {
  display: flex;
  max-width: 1080px;
  margin: 0 auto;
  padding: 16px 40px;
  align-items: center;
  color: var(--stier-white);
}

.header-logo {
  background-image: url("../assets/Stier-logo-small.svg");
  height: 32px;
  width: 32px;
  background-size: contain;
  margin-right: 8px;
  background-repeat: no-repeat;
}

.theme-btn {
  margin-left: auto;
  margin-right: 0px;
  height: 32px;
}

.theme-btn label {
  display: block;
}

.username {
  margin-left: 16px;
  margin-right: 16px;
}

@media (min-width: 1120px) {
  .header-container {
    padding: 16px 0px;
  }
}
</style>
