<script setup lang="ts">
import NavigationHeader from "@/components/NavigationHeader.vue";
import AppUpdatePrompt from "@/components/AppUpdatePrompt.vue";
import { useAuth0 } from "@auth0/auth0-vue";
import LogRocket from "logrocket";
import { watchEffect } from "vue";
import { RouterView } from "vue-router";

// Redirect any user to Auth0 for login if they aren't already authenticated.
const { loginWithRedirect, isAuthenticated, isLoading, user } = useAuth0();
watchEffect(async () => {
  if (!isLoading.value && !isAuthenticated.value) {
    await loginWithRedirect();
  }

  if (isAuthenticated.value) {
    LogRocket.identify(user.value.sub ?? "", user.value);
  }
});
</script>

<template>
  <template v-if="isAuthenticated">
    <navigation-header />
    <router-view />
    <app-update-prompt />
  </template>
</template>
