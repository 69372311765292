import localforage from "localforage";
import { createPinia } from "pinia";
import { createPersistedStatePlugin } from "pinia-plugin-persistedstate-2";
import { toRaw } from "vue";

localforage.config({
  driver: localforage.INDEXEDDB,
});

const pinia = createPinia().use(
  createPersistedStatePlugin({
    serialize: (state) => toRaw(state),
    deserialize: (state) => state,
    storage: {
      getItem: async (key) => {
        return localforage.getItem(key);
      },
      setItem: async (key, value) => {
        return localforage.setItem(key, value);
      },
      removeItem: async (key) => {
        return localforage.removeItem(key);
      },
    },
  })
);

export default pinia;
